<template>
    <div class="followers-list">
        <div class="container main-content">
            <div class="row no-gutters followers-list-header">
                <div class="col-auto followers-list-back">
                    <div class="back-button" @click="$emit('hide-user-followers')">
                        <img src="@assets/img/icons/left-chevron.svg">
                    </div>
                </div>
                <div class="col followers-list-title">
                    <h1>Followers</h1>
                </div>
            </div>
            <people-list use-api-v2 :list-endpoint="`/users/${userId}/followers?&format=true`" />
        </div>
    </div>
</template>

<script>
export default {
    name: "FollowersList",
    components: {
        PeopleList: () => import(/* webpackChunkName: "people-list" */ "@/components/organisms/people-list.vue")
        // Boards: () => import(/* webpackChunkName: "boards-list" */ "@/components/organisms/boards-list.vue"),
        // Topics: () => import(/* webpackChunkName: "topics-list" */ "@/components/organisms/topics-list.vue")
    },
    props: {
        userId: {
            type: Number,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.followers-list {
    .main-content {
        padding-top: 100px;
        margin-bottom: 120px;

        .back-button {
            margin-right: 40px;
            width: 40px;
            height: 40px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 10px;
            }
        }

        h1 {
            padding-top: 7px;
            margin-bottom: 15px;
        }

        .followers-categories {
            display: flex;
            justify-content: space-between;

            .links {
                display: flex;
                padding-top: 25px;
                padding-bottom: 25px;
                margin-left: auto;
                margin-right: auto;
                .link {
                    display: block;
                    margin-left: 65px;
                    cursor: pointer;
                    padding: 6px 20px;
                    border-radius: 100px;
                    color: white;
                    text-decoration: none;
                    @media(max-width: $lg) {
                        margin-left: 0;
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                    &.is-active {
                        background-color: $primary-color;
                    }
                }
            }
        }


        @media(max-width: $md) {
            padding-top: 20px;

            .back-button {
                margin-right: 40px;
            }

            h1 {
                padding-top: 7px;
                margin-bottom: 15px;
            }

            .followers-list-header {
                position: relative;

                .followers-list-back {
                    order: 1;
                    flex-basis: 100%;
                }

                .followers-list-title {
                    order: 3;

                    h1 {
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>
